import { ABOUT_US_BLOCK_DATA } from "constants/common.constants";
import AboutUsImage from "images/aboutUs.png";
import { Box, Stack, Typography } from "@mui/material";
import { cl } from "./About.styles";

export const About = () => {
  return (
    <Stack sx={cl.root} id="about">
      <Stack sx={cl.textContainer}>
        <Typography variant="caption" sx={cl.captureText}>
          {ABOUT_US_BLOCK_DATA.captureText}
        </Typography>
        <Typography variant="h2" sx={cl.title}>
          {ABOUT_US_BLOCK_DATA.title}
        </Typography>
        <Typography variant="h5" sx={cl.subtitle}>
          {ABOUT_US_BLOCK_DATA.subtitle}
        </Typography>
        <Stack sx={cl.description}>
          {ABOUT_US_BLOCK_DATA.description.map((item, index) => (
            <Typography key={index} sx={cl.descriptionItem}>
              {item}
            </Typography>
          ))}
        </Stack>
      </Stack>
      <Stack sx={cl.imageContainer}>
        <Box component="img" sx={cl.image} src={AboutUsImage} alt="about-us-image" />
      </Stack>
    </Stack>
  );
};
