import { CEO_BLOCK_DATA } from "constants/common.constants";
import CEOImage from "images/CEO.png";
import { Box, Stack, Typography } from "@mui/material";
import { CheckIcon } from "Icons/CheckIcon";
import { cl } from "./Founder.styles";

export const Founder = () => {
  return (
    <Stack sx={cl.root} id="out_team">
      <Stack sx={cl.textContainer}>
        <Stack sx={cl.image}>
          <Box component="img" src={CEOImage} alt="about-us-image" />
        </Stack>
        <Stack gap="12px">
          <Typography variant="h2">{CEO_BLOCK_DATA.title}</Typography>
          <Typography variant="caption" sx={cl.captureText}>
            {CEO_BLOCK_DATA.captureText}
          </Typography>
        </Stack>
      </Stack>

      <Stack sx={cl.descriptionContainer}>
        {CEO_BLOCK_DATA.description.map((item, index) => (
          <Stack key={index} sx={cl.descriptionItem}>
            <CheckIcon />
            <Typography sx={cl.descriptionText}>{item}</Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
