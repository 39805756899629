import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";
import { useLocation } from "react-router-dom";
import * as Yup from "yup";

import { TextField } from "../inputs/textInput";
import { UploadFile } from "../inputs/uploadFile";

import { clearErrors, fetchDatasetNames, fetchDatasets, openCreateDialog } from "../../actions/datasets";
import { createDatasetId } from "../../services/datasets";
import { useSetFileTrack } from "../../hooks/useSetFileTrack";
import { setLoading } from "../../actions/user";
import { MAX_IMAGE_SIZE, SOCKET_STATUS } from "./constants";
import { toast } from "react-toastify";
import { useFormik } from "formik";
import { handleLargeFileUpload, uploadDatasetFile } from "../../services/datasets-s3";
import { Typography } from "@mui/material";
import { StarsIcon } from "../../Icons/StarsIcon";
import { addChat } from "actions/chats";
import { gaClickCallback } from "helpers/common";

const DEFAULT_VALUES = {
  datasetName: "",
  datasetFile: "",
  description: "",
};

export function UploadDataset({ open, handleClose }) {
  const dispatch = useDispatch();
  const { pathname } = useLocation();

  const [datasetId, setDatasetId] = useState(null);

  const { loading, response } = useSetFileTrack({
    dataset_id: datasetId,
    token: localStorage.getItem("session_token"),
  });

  const isChatRout = pathname === "/genie-gpt";

  const selectedProject = useSelector((state) => state.projectsReducer.selected);
  const { id } = useSelector((state) => state.userReducer.data);
  const errors = useSelector((state) => state.datasetsReducer.errors);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: DEFAULT_VALUES,
    onSubmit: (submitData) => {
      const filters = {
        dataset_name: submitData.datasetName,
        ...(selectedProject.id ? { project_id: selectedProject.id } : { from_statgpt: true }),
        comment: submitData.description,
      };
      dispatch(setLoading(true));
      createDatasetId(filters).then(({ data }) => {
        setDatasetId(data.dataset_id);
        const fileSizeKiloBytes = submitData.datasetFile.size / 1024;
        if (fileSizeKiloBytes >= MAX_IMAGE_SIZE) {
          handleLargeFileUpload(
            submitData.datasetFile,
            data.dataset_id,
            selectedProject.id || data.project_id,
            id
          );
        } else {
          uploadDatasetFile(
            submitData.datasetFile,
            data.dataset_id,
            selectedProject.id || data.project_id,
            id
          );
        }
      });
      dispatch(clearErrors());
    },
    validationSchema: Yup.object().shape({
      datasetName: Yup.string()
        .required("Required field")
        .min(4, "Ensure this value has at least 4 characters"),
      datasetFile: Yup.mixed()
        .required("Required field")
        .test("fileName", 'The name of the file cannot include "."', (value) => {
          if (!value) return true;
          const fileName = value.name.replace(/\.[^/.]+$/, "");
          return fileName.indexOf(".") === -1;
        }),
    }),
  });

  const handleChangeFile = (event) => {
    const { files } = event.target;
    if (files.length > 0) {
      const originalFile = files[0];

      let sanitizedFileName = originalFile.name
        .trim()
        .replace(/\.[^/.]+$/, "")
        .replace(/[^a-zA-Z0-9]/g, "");

      if (!sanitizedFileName) {
        sanitizedFileName = "dataset";
      }

      const fileNameParts = originalFile.name.split(".");
      const fileTypeFromName = fileNameParts[fileNameParts.length - 1];

      const sanitizedFile = new File([originalFile], `${sanitizedFileName}.${fileTypeFromName}`, {
        type: originalFile.type,
      });

      formik.setFieldValue("datasetFile", sanitizedFile, false);
    }
  };

  const onClose = () => {
    formik.resetForm();
    dispatch(clearErrors());
    handleClose();
  };

  useEffect(() => {
    if (loading) {
      dispatch(setLoading(true));
    } else {
      dispatch(setLoading(false));
    }
  }, [dispatch, loading]);

  useEffect(() => {
    if (response) {
      switch (response.event) {
        case SOCKET_STATUS.READY:
          dispatch(openCreateDialog(false));
          setDatasetId(null);
          formik.resetForm();
          dispatch(addChat({ dataset: { id: datasetId, name: formik.values.datasetName } }));
          if (isChatRout) {
            dispatch(fetchDatasetNames());
          } else {
            dispatch(fetchDatasets());
          }
          break;
        case SOCKET_STATUS.ERROR:
          toast.error("Failed to upload Dataset. Please, try again");
          setDatasetId(null);
          break;
        default:
          break;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isChatRout, response]);

  const checkError = (field) => {
    let error = "";
    errors.forEach((err) => {
      if (err.loc[1] === field) {
        error = err.msg;
      }
    });

    return error;
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Upload Dataset</DialogTitle>
      <form onSubmit={formik.handleSubmit}>
        <DialogContent sx={{ gap: "20px", display: "flex", flexDirection: "column" }}>
          <TextField
            absoluteErrorLabel
            name="datasetName"
            label="Dataset Name*"
            inputProps={{
              placeholder: "Enter dataset name",
            }}
            sx={{
              "label + &": {
                marginTop: "22px !important",
              },
            }}
            labelSx={{
              transform: "none",
            }}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
            error={
              checkError("dataset_name") ||
              (!!formik.errors?.datasetName && !!formik.touched?.datasetName && formik.errors?.datasetName)
            }
            value={formik.values?.datasetName}
          />
          <UploadFile
            absoluteErrorLabel
            labelElement={
              <Typography fontSize="16px" textAlign="center" lineHeight="24px" color="#101828">
                <span style={{ color: "#6941C6", fontWeight: "600" }}>Click to upload </span>or drag and drop
                file here <br />( csv, xlsx, etc )
              </Typography>
            }
            accept=".csv, .xls, .xlsx, .dta, .sas, .sav, .sas7bdat"
            value={formik.values.datasetFile}
            onBlur={formik.handleBlur}
            onChange={(event) => handleChangeFile(event)}
            error={
              !!formik.errors?.datasetFile &&
              !!formik.touched?.errors?.datasetFile &&
              !!formik.errors?.datasetFile
            }
          />

          <TextField
            absoluteErrorLabel
            multiline
            labelIcon={<StarsIcon />}
            rows={5.5}
            name="description"
            label="Description (Recommended)"
            inputProps={{
              placeholder:
                "Describe, in your own words, the primary objective or hypothesis of your analysis",
            }}
            sx={{
              "label + &": {
                marginTop: "20px !important",
              },
            }}
            labelSx={{
              transform: "none",
              display: "flex",
              alignItems: "center",
              gap: "6px",
            }}
            value={formik.values.description}
            onBlur={formik.handleBlur}
            onChange={formik.handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            onClick={onClose}
            sx={{ p: "6.5px 14px", height: "40px", color: "#344054", borderColor: "#D0D5DD", mr: "4px" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            color="primary"
            type="submit"
            onClick={() =>
              gaClickCallback({
                label: "Create Dataset on upload dataset popup",
              })
            }
            sx={{ p: "8px 14px" }}
          >
            Create Dataset
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
}
