export const cl = {
  imageContainer: {
    aspectRatio: "16 / 9",
    "& img": {
      aspectRatio: "16 / 9",
      objectFit: "contain",
      width: "100%",
      height: "100%",
      backgroundColor: "#ffff",
      borderRadius: "14px",
      boxSizing: "border-box",
      border: { xs: "7px solid #EBEBF5", sm: "1px #EAECF0 solid" },
    },
    boxSizing: "border-box",
    display: "flex",
    backgroundColor: "#ffff",
    borderRadius: "14px",
    position: "relative",
    zIndex: 10,
  },
  sliderImageContainer: {
    p: { xs: "0", sm: "24px 24px 24px 0" },
    width: "100%",
    height: "calc(100% + 19px)",
    aspectRatio: "16 / 9",
    backgroundColor: "#F9FAFB",
    boxSizing: "border-box",
    "& img": {
      aspectRatio: "16 / 9",
      objectFit: "contain",
      width: "100%",
      height: "100%",
      backgroundColor: "#ffff",
      borderRadius: "14px",
      boxSizing: "border-box",
      border: { xs: "7px solid #EBEBF5", sm: "1px #EAECF0 solid" },
    },
  },
  sliderImageRow: {
    backfaceVisibility: "hidden",
    display: "flex",
    gap: { xs: "24px", sm: "50px" },
    touchAction: "pan-y",
  },
  sliderImageItem: {
    minWidth: 0,
    position: "relative",
    flex: "0 0 100%",
  },
  descriptionText: {
    fontSize: { xs: "14px", sm: "18px" },
    lineHeight: { xs: "20px", sm: "26px" },
    color: "#475467",
    fontWeight: { xs: 400, sm: 500 },
    whiteSpace: "pre-line",
    textAlign: { xs: "center", sm: "left" },
  },
  titleText: {
    fontSize: { xs: "23px", sm: "36px" },
    textAlign: { xs: "center", sm: "left" },
    lineHeight: { xs: "32px", sm: "44px" },
    whiteSpace: "pre-line",
  },
  textContainer: {
    width: { xs: "100%", sm: "40%" },
    gap: "4px",
  },
  root: {
    gap: { xs: "48px", sm: "100px" },
    width: "100%",
    boxSizing: "border-box",
    p: { xs: "24px 16px", sm: "102px 80px 80px" },
    backgroundColor: "#F9FAFB",
    flexDirection: "column",
  },
};
