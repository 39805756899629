import { useState } from "react";

import { cl, MobileNavbarWrapper, MobileNavContainer, OptimizationBlockContainer } from "../styles";
import { Logo } from "components/logo";
import { Box, Typography } from "@mui/material";
import { SmallNotificationIcon } from "Icons/SmallNotificationIcon";
import { MenuContent } from "./MenuContent";

export const MobileHeader = ({ setOpenLogin, setOpenRegister }) => {
  const [isOpen, setOpen] = useState(false);

  const handleIsOpen = () => {
    setOpen(!isOpen);

    if (isOpen) {
      document.body.style.overflow = "auto";
    } else {
      document.body.style.overflow = "hidden";
    }
  };

  return (
    <MobileNavbarWrapper position="sticky">
      <MobileNavContainer>
        <Logo />

        <Box sx={cl.burgerMenu}>
          <Box sx={isOpen ? cl.open : cl.navIcon} onClick={handleIsOpen}>
            <span></span>
            <span></span>
            <span></span>
          </Box>
        </Box>
      </MobileNavContainer>

      <OptimizationBlockContainer direction="row">
        <SmallNotificationIcon />

        <Typography fontSize="12px" lineHeight="16px" color="#0A0D14">
          Genie Statistics is optimized for desktop use. For the best experience, we suggest using it on a
          desktop.
        </Typography>
      </OptimizationBlockContainer>
      <MenuContent
        open={isOpen}
        setOpen={handleIsOpen}
        {...{ open: isOpen, setOpen: handleIsOpen, setOpenLogin, setOpenRegister }}
      />
    </MobileNavbarWrapper>
  );
};
