export const cl = {
  root: {
    flexDirection: { xs: "column", sm: "row" },
    width: "100%",
    justifyContent: { xs: "flex-start", sm: "space-between" },
    alignItems: "center",
    gap: { xs: "24px", sm: "20px" },
    height: { xs: "100%", sm: "calc(100svh - 104.5px)" },
    p: { xs: "16px", sm: "0px 80px" },
    boxSizing: "border-box",
    textAlign: { xs: "center", sm: "left" },
  },
  textContainer: {
    justifyContent: "center",
    alignItems: { xs: "center", sm: "flex-start" },
    width: { xs: "100%", sm: "40%" },
    gap: { xs: "8px", sm: "24px" },
  },
  button: {
    mt: { xs: "16px", sm: "24px" },
    width: { xs: "198px", sm: "225px" },
    height: { xs: "44px", sm: "60px" },
    fontSize: "16px",
  },
  videoContainer: {
    width: { xs: "100%", sm: "55%" },
    justifyContent: "center",
    alignItems: "end",
    aspectRatio: "16 / 9",
    zIndex: 100,
    maxHeight: "70%",
    height: "100%",
    border: "1px #EAECF0 solid",
    borderRadius: { xs: "8px", sm: "20px" },
    overflow: "hidden",
    "& video": {
      width: "100%",
      height: "100%",
    },
  },
};
