import { useNavigate } from "react-router-dom";

import { Logo } from "components/logo";

import { Button, Stack } from "@mui/material";
import { SubTitleText, TitleText } from "pages/home/styles";

export default function ErrorVerification() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100svh"
      textAlign="center"
      gap="30px"
      width="100%"
    >
      <Logo sx={{ width: "180px", height: "38px" }} />

      <Stack gap="6px">
        <TitleText sx={{ fontSize: "28px", lineHeight: "40px" }}>Verification Failed</TitleText>
        <SubTitleText sx={{ fontSize: "18px", lineHeight: "26px" }}>
          Sorry, we couldn’t verify your account. Please try to sign up again
        </SubTitleText>
      </Stack>

      <Button
        sx={{ width: "220px", height: "46px" }}
        variant="contained"
        color="primary"
        onClick={() => navigate("/?signUp=true")}
      >
        Sign Up
      </Button>
    </Stack>
  );
}
