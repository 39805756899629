import { useEffect, useState } from "react";

import { AnalyseTable } from "../AnalyseTable";

import { Accordion, AccordionDetails, AccordionSummary, Box, Typography } from "@mui/material";
import { cl } from "../AnalyseGraph/styles";
import { ArrowDown } from "Icons/ArrowDown";

export const FrequencyAccordion = ({ data, selectedShowTab, currentAnalyse, tabData, graphs }) => {
  const [keys, setKeys] = useState([]);

  useEffect(() => {
    if (!data && !Object.keys(data)?.length) return;
    setKeys(Object.keys(data));
  }, [data]);

  const getTableData = (data) => {
    if (!data || !Object.keys(data)?.length) return [];

    return Object.values(data) || [];
  };

  const getTableElement = (key) => {
    switch (selectedShowTab) {
      case "Frequency":
        return <AnalyseTable data={getTableData(data?.[key]?.[selectedShowTab?.toLowerCase()]) || []} />;
      case "Summary":
        return <AnalyseTable data={getTableData(data?.[key]?.[selectedShowTab?.toLowerCase()]) || []} />;
      default:
        return <></>;
    }
  };

  return (
    <Box>
      {!!keys.length &&
        keys.map((key, index) => (
          <Accordion
            key={index}
            defaultExpanded={!index}
            sx={{
              ...cl.accordion,
              "&.Mui-expanded": {
                margin: "0 0 10px!important",
              },
            }}
            PaperProps={{
              sx: cl.accordionPaper,
            }}
          >
            <AccordionSummary expandIcon={<ArrowDown />} sx={cl.accordionSummary}>
              <Typography>{key}</Typography>
            </AccordionSummary>
            <AccordionDetails sx={cl.accordionDetails}>{getTableElement(key)}</AccordionDetails>
          </Accordion>
        ))}
    </Box>
  );
};
