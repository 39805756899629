import { ListItem, List, AppBar, Stack } from "@mui/material";
import { styled } from "@mui/system";

export const ListContainer = styled(List)(() => ({
  display: "flex",
  padding: 0,
  gap: "32px",
}));

export const MobileListContainer = styled(List)(() => ({
  display: "flex",
  flexDirection: "column",
  padding: "20px",
  boxSizing: "border-box",
  gap: "16px",
  width: "100%",
}));

export const StyledListItem = styled(ListItem)(() => ({
  width: "fit-content",
  textWrap: "nowrap",
  cursor: "pointer !important",
  fontWeight: 600,
  transition: "0.2s",
  color: "#475467",
  fontSize: "16px",
  lineHeight: "24px",

  "&:hover": {
    color: "#6941C6",
    transition: "0.2s",
  },
}));

export const MobileNavbarWrapper = styled(AppBar)(() => ({
  display: "flex",
  padding: 0,
  flexDirection: "column",
  justifyContent: "center",
  boxSizing: "border-box",
  //height: "128px",
  height: "fit-content",
}));

export const MobileNavContainer = styled("nav")(() => ({
  display: "flex",
  gap: "32px",
  justifyContent: "space-between",
  alignItems: "center",
  width: "100%",
  padding: "16px",
  boxSizing: "border-box",
  height: "72px",
}));

export const OptimizationBlockContainer = styled(Stack)(() => ({
  width: "100%",
  height: "fit-content",
  padding: "12px 16px",
  background: "#EBF1FF",
  gap: "8px",
  boxSizing: "border-box",
}));

export const cl = {
  menu: {
    position: "fixed",
    top: "128px",
    width: "100vw",
    height: "100vh",
    transform: "translateX(-300%)",
    transition: "all 0.3s",
    left: "0",
    zIndex: "101",
  },

  menuActive: {
    transition: "all 0.3s",
    transform: "translateX(0)",
  },

  menuWrapper: {
    width: "100%",
    height: "100vh",
    backgroundColor: "#ffff",
    zIndex: "100",
    transition: "all 0.3s",
  },

  burgerMenu: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
  },

  navIcon: {
    width: "20px",
    height: "12px",
    position: "relative",
    transform: "rotate(0deg)",
    transition: "0.5s ease-in-out",
    cursor: "pointer",

    "& span": {
      display: "block",
      position: "absolute",
      height: "2px",
      width: "100%",
      backgroundColor: "rgb(52, 64, 84)",
      borderRadius: "9px",
      left: "0",
      transform: "rotate(0deg)",
      transition: "0.25s ease-in-out",
      cursor: "pointer",
    },

    "& span:nth-of-type(1)": {
      top: "0px",
      transformOrigin: "left center",
    },

    "& span:nth-of-type(2)": {
      top: "6px",
      transformOrigin: "left center",
    },

    "& span:nth-of-type(3)": {
      top: "12px",
      transformOrigin: "left center",
    },
  },

  open: {
    width: "20px",
    height: "12px",
    position: "relative",
    transform: "rotate(0deg)",
    transition: "0.5s ease-in-out",
    cursor: "pointer",

    "& span": {
      display: "block",
      position: "absolute",
      height: "2px",
      width: "100%",
      backgroundColor: "rgb(52, 64, 84)",
      borderRadius: "9px",
      left: "0",
      transform: "rotate(0deg)",
      transition: "0.25s ease-in-out",
      cursor: "pointer",
    },

    "& span:nth-of-type(1)": {
      transform: "rotate(45deg)",
      top: "6px",
      left: "-1px",
    },

    "& span:nth-of-type(2)": {
      opacity: "0",
      width: "0%",
    },

    "& span:nth-of-type(3)": {
      transform: "rotate(-45deg)",
      top: "6px",
      left: "-1px",
    },
  },
};
