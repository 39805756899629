import React from "react";
import Autoplay from "embla-carousel-autoplay";
import useEmblaCarousel from "embla-carousel-react";

import { AnalysesItem } from "./AnalysesItem";
import { cl } from "../styles";

import { Box } from "@mui/material";

export const AnalysesSlider = ({ images, isMobile }) => {
  const [emblaRef] = useEmblaCarousel({ containScroll: "trimSnaps" }, [Autoplay()]);

  return (
    <Box overflow="hidden" ref={emblaRef} sx={cl.sliderImageContainer}>
      {!!images?.length && (
        <Box sx={cl.sliderImageRow}>
          {images.map((item, index) => (
            <Box key={index} sx={cl.sliderImageItem}>
              <AnalysesItem url={item} alt="analyses-slider-image" isMobile={isMobile} />
            </Box>
          ))}
        </Box>
      )}
    </Box>
  );
};
