import { useMediaDevice } from "hooks/useMediaDevice";
import { DesktopHeader } from "./components/DesktopHeader";
import { MobileHeader } from "./components/MobileHeader";

export const Header = ({ setOpenLogin, setOpenRegister }) => {
  const { isMobile } = useMediaDevice();
  return (
    <>
      {isMobile ? (
        <MobileHeader {...{ setOpenLogin, setOpenRegister }} />
      ) : (
        <DesktopHeader {...{ setOpenLogin, setOpenRegister }} />
      )}
    </>
  );
};
