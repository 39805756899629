import { HeaderBlock } from "components/login/components/HeaderBlock";
import { StyledDialog, StyledDialogContent } from "../styles";

import Button from "@mui/material/Button";

export const SuccessCreatePopup = ({ open, setOpen }) => {
  return (
    <StyledDialog
      open={open}
      onClose={() => setOpen(false)}
      fullWidth
      sx={{
        "& .MuiDialog-paper": {
          maxWidth: "510px",
        },
      }}
    >
      <HeaderBlock
        title="Please Verify Your Account"
        subTitle=" We've sent you an email to verify your account. Please open it and click the link to proceed."
        onClose={() => {
          console.log("ee");
          setOpen(false);
        }}
        headerWrapperProps={{
          sx: {
            padding: "42px 28px 20px",
            "& h5": {
              fontSize: "17px",
              lineHeight: "24px",
            },
            "& h4": {
              marginTop: "26px",
              fontSize: "21px",
            },
          },
        }}
      />

      <StyledDialogContent
        sx={{
          gap: "56px",
          mt: "10px",
          padding: "32px !important",
        }}
      >
        <Button variant="contained" color="primary" onClick={() => setOpen(false)}>
          Close
        </Button>
      </StyledDialogContent>
    </StyledDialog>
  );
};
