import { v4 as uuidv4 } from "uuid";

import { toast } from "react-toastify";
import { getChatName } from "../helpers/getChatName";
import {
  getChats,
  createChat,
  deleteChat,
  updateChat,
  getChatById,
  createMessage,
  createStatMessage,
} from "../services/chats";
import { setLoading } from "./user";
import { initialState } from "reducer/chats";

export const SET_CHATS = "CHATS/SET_CHATS";
export const RESET_CHATS = "CHATS/RESET_CHATS";
export const SET_EXPORT_DIALOG = "CHATS/SET_EXPORT_DIALOG";
export const SET_ERRORS = "CHATS/SET_ERRORS";
export const SET_ACTIVE_CHAT = "CHATS/SET_ACTIVE_CHAT";
export const SET_DEFAULT_ACTIVE_CHAT = "SET_DEFAULT_ACTIVE_CHAT";
export const SET_ACTIVE_CHAT_MESSAGES = "SET_ACTIVE_CHAT_MESSAGES";
export const SET_ACTIVE_DATASET = "CHATS/SET_ACTIVE_DATASET";

export const fetchChats = (firstFetch = false, messageData = {}, skipCancelLoading = false) => {
  return (dispatch, getState) => {
    dispatch(setLoading(true));
    getChats(getState().chatsReducer.filters)
      .then((res) => {
        dispatch({
          type: SET_CHATS,
          list: res.data.records || [],
          count: res.data.count,
        });

        if (firstFetch && !!res.data.records.length) dispatch(setActiveChat(res.data.records[0]));
        if (!!Object.keys(messageData).length) {
          dispatch(fetchMessages(messageData, res.data.records[0]));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.detail[0].msg);
      })
      .finally(() => {
        if (!skipCancelLoading) dispatch(setLoading(false));
      });
  };
};

export const setDefaultActiveChat = () => {
  return (dispatch) => {
    dispatch({ type: SET_DEFAULT_ACTIVE_CHAT });
  };
};

export const resetChats = () => {
  return (dispatch) => {
    dispatch({ type: RESET_CHATS });
  };
};

export const setExportDialog = (openedExportDialog, exportAnalysis_id) => {
  return (dispatch) => {
    dispatch({ type: SET_EXPORT_DIALOG, openedExportDialog, exportAnalysis_id });
  };
};

export const setCurrentChat = (data, id) => {
  return (dispatch, getState) => {
    const { dataset_id, analysis_id } = getState().chatsReducer.activeChat;

    dispatch({
      type: SET_ACTIVE_CHAT,
      activeChat: {
        id,
        messages: data.records.reverse(),
        name: data.name || "New Chat",
        dataset_id: data.dataset_id || dataset_id,
        analysis_id: data.analysis_id || analysis_id,
      },
    });
  };
};

export const addChat = ({ name = "New Chat", message = {}, dataset = null }) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    if (dataset)
      return createChat({ name: dataset.name, dataset_id: dataset.id })
        .then(() => {
          if (!!Object.keys(message).length) {
            dispatch(fetchChats(true, message));
          } else {
            dispatch(fetchChats(true));
          }
        })
        .catch((err) => {
          toast.error(err.response.data.detail[0].msg);
        })
        .finally(() => dispatch(setLoading(false)));

    return createChat({ name })
      .then(() => {
        if (!!Object.keys(message).length) {
          dispatch(fetchChats(true, message));
        } else {
          dispatch(fetchChats(true));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.detail[0].msg);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const removeChat = (id) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    deleteChat(id)
      .then(() => {
        dispatch(setDefaultActiveChat());
        dispatch(fetchChats(true));
      })
      .catch((err) => {
        toast.error(err.response.data.detail[0].msg);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const editChat = (id, data, skipStopLoading = false) => {
  return (dispatch) => {
    dispatch(setLoading(true));
    updateChat(id, data)
      .then(() => {
        dispatch(fetchChats(false, {}, true));
      })
      .catch((err) => {
        toast.error(err.response.data.detail[0].msg);
      })
      .finally(() => {
        if (!skipStopLoading) dispatch(setLoading(false));
      });
  };
};

export const resetToDefault = () => {
  return (dispatch) => {
    dispatch({ type: SET_ACTIVE_DATASET, activeDataset: {} });
    dispatch({
      type: SET_ACTIVE_CHAT,
      activeChat: initialState.activeChat,
    });
  };
};

export const setActiveDataset = (id) => {
  return (dispatch, getState) => {
    if (!id) return;

    const dataset = getState().datasetsReducer.namesList?.find((dataset) => dataset?.id === id);

    if (dataset) dispatch({ type: SET_ACTIVE_DATASET, activeDataset: dataset });
  };
};

export const setActiveChat = (chat, setAttrubutes = false) => {
  return (dispatch, getState) => {
    //const { analysis_id } = getState().chatsReducer.activeChat;
    dispatch(setLoading(true));
    dispatch(setActiveDataset(chat.dataset_id));
    getChatById(getState().chatsReducer.chatByIdFilters, chat.id)
      .then(({ data }) => {
        if (setAttrubutes && !!data.records.length) {
          //const lastMessage = data.records[0];
          const newChatData = {
            records: data.records,
            name: data.name || "New Chat",
            dataset_id: chat.dataset_id,
            //dataset_id: lastMessage.dataset_id || dataset_id,
            //analysis_id: lastMessage.analysis_id || analysis_id,
          };
          dispatch(setCurrentChat(newChatData, chat.id));
        } else {
          dispatch(setCurrentChat({ ...data, dataset_id: chat.dataset_id }, chat.id));
        }
      })
      .catch((err) => {
        toast.error(err.response.data.detail[0].msg);
      })
      .finally(() => dispatch(setLoading(false)));
  };
};

export const addFirstMessage = (messageData) => {
  return (dispatch) => {
    const chatName = getChatName(messageData.data.user_text);
    dispatch(editChat(messageData.data.chat_id, { name: chatName }, true));
    dispatch(
      addMessage({
        ...messageData,
        data: { ...messageData.data, name: chatName },
      })
    );
  };
};

export const fetchMessages = (messageData, newChat) => {
  return (dispatch) => {
    const data = {
      ...messageData,
      data: {
        ...messageData.data,
        name: newChat.name,
        chat_id: newChat.chat_id || newChat.id,
      },
    };
    dispatch(addMessage(data));
  };
};

export const addErrorMessage = (error_text) => {
  return (dispatch, getState) => {
    const { id, dataset_id, analysis_id } = getState().chatsReducer.activeChat;

    const messageData = {
      chat_id: id,
      identity: "system",
      text: "",
      dataset_id,
      analysis_id,
      error_text,
    };

    dispatch(setLoading(true));
    createMessage(messageData).then(() => {
      getChatById(getState().chatsReducer.chatByIdFilters, id)
        .then(({ data }) => {
          dispatch(setCurrentChat(data, id));
        })
        .finally(() => dispatch(setLoading(false)));
    });
  };
};

export const addMessage = (messageData) => {
  return (dispatch, getState) => {
    const { id, dataset_id, messages, analysis_id } = getState().chatsReducer.activeChat;

    const message = {
      chat_id: messageData.data.chat_id || id,
      identity: "user",
      text: messageData.data.user_text,
      dataset_id: messageData.data.dataset_id || dataset_id,
      analysis_id: messageData.data.analysis_id || analysis_id,
      error_text: "",
    };
    dispatch(setLoading(true));
    createMessage(message).then(() => {
      dispatch({
        type: SET_ACTIVE_CHAT_MESSAGES,
        action: [...messages, { ...message, id: uuidv4() }],
      });
      dispatch(addStatMessage(messageData));
    });
  };
};

export const addStatMessage = (messageData) => {
  return (dispatch, getState) => {
    createStatMessage(messageData)
      .then((res) => {
        getChatById(getState().chatsReducer.chatByIdFilters, messageData.data.chat_id)
          .then(({ data }) => {
            dispatch(setCurrentChat({ ...data, ...res.data }, messageData.data.chat_id));
            dispatch(setLoading(false));
          })
          .catch((err) => {
            toast.error(err.response.data.detail[0].msg);
          });
      })
      .catch((err) => {
        dispatch(addErrorMessage(err.response.data.detail[0].msg || err.response.data.detail));
      });
  };
};
