import { addChat, addFirstMessage, addMessage } from "actions/chats";
import { getChatName } from "helpers/getChatName";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { getGptPromptService } from "services/chats";

export const useActiveChat = () => {
  const dispatch = useDispatch();
  const [arrowStep, setArrowStep] = useState(0);

  const [activeChatState, setActiveChatState] = useState({
    suggestionsList: {},
    firstLoading: true,
    userMessages: [],
    message: "",
    suggestionsLoading: true,
    isExpandedList: false,
  });

  const { userMessages, firstLoading, suggestionsList, message, suggestionsLoading } = activeChatState;

  const onStateChange = (data) => setActiveChatState((prev) => ({ ...prev, ...data }));

  const { data } = useSelector((state) => state.userReducer);
  const { id, messages, dataset_id, name } = useSelector((state) => state.chatsReducer.activeChat);

  const filteredMessagesWithAnalyses = useMemo(() => {
    if (!messages.length) return [];
    return messages.filter(({ analysis_id }) => analysis_id !== null);
  }, [messages]);

  const hasAnalysesInMessages = useMemo(() => {
    if (!filteredMessagesWithAnalyses.length) return false;
    return !!filteredMessagesWithAnalyses;
  }, [filteredMessagesWithAnalyses]);

  const sendMessage = useCallback(
    (text) => {
      onStateChange({ isExpandedList: false });
      const data = {
        file_configuration: {
          width: 1200,
          height: 1200,
          resolution: 200,
        },
        data: {
          name,
          chat_id: id,
          user_text: text,
          dataset_id,
          analysis_id: filteredMessagesWithAnalyses.length
            ? filteredMessagesWithAnalyses?.[filteredMessagesWithAnalyses?.length - 1]?.analysis_id
            : null,
          round_digits: 3,
          significance_level: 0.05,
          significance_level_normality: 0.05,
        },
      };

      if (!id) {
        const chatName = getChatName(text, data);
        dispatch(addChat({ name: chatName, message: data }));
      } else {
        if (!messages.length) {
          dispatch(addFirstMessage(data));
        } else {
          dispatch(addMessage(data));
        }
      }
      onStateChange({ firstLoading: false });
    },
    [filteredMessagesWithAnalyses, dataset_id, dispatch, id, messages.length, name]
  );

  const getGptPrompts = async () => {
    if (messages.length) onStateChange({ suggestionsLoading: false });

    if (!suggestionsLoading) onStateChange({ suggestionsLoading: true });
    await getGptPromptService(dataset_id)
      .then((res) => {
        onStateChange({ suggestionsList: res?.data?.prompts || {} });
      })
      .finally(() => onStateChange({ suggestionsLoading: false }));
  };

  useEffect(() => {
    if (!firstLoading) {
      onStateChange({ firstLoading: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    onStateChange({ suggestionsList: {} });
    if (!dataset_id) return;
    getGptPrompts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dataset_id, id]);

  const onChange = (event) => {
    const { value } = event.target;
    onStateChange({ message: value });
  };

  const filterMessagesByUser = useCallback((messages) => {
    if (!messages.length) return [];
    const filteredList = messages.filter(({ identity }) => identity === "user");
    return onStateChange({ userMessages: !!filteredList.length ? filteredList.reverse() : [] });
  }, []);

  useEffect(() => {
    filterMessagesByUser(messages);
    setArrowStep(0);
    onStateChange({ message: "" });
  }, [filterMessagesByUser, messages]);

  useEffect(() => {
    if (arrowStep) {
      onStateChange({ message: userMessages[arrowStep - 1]?.text });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [arrowStep]);

  const handleSubmit = useCallback(() => {
    if (message.length > 1) {
      sendMessage(message);
      return onStateChange({ message: "" });
    }
    return toast.error("Message should have more than 1 letter");
  }, [message, sendMessage]);

  const handleSetExampleMessage = (example) => {
    onStateChange({ message: example });
  };

  const handleIncreaseArrowStep = useCallback(() => {
    if (arrowStep + 1 > userMessages.length) return;
    setArrowStep((prev) => prev + 1);
  }, [arrowStep, userMessages.length]);

  const handleDecreaseArrowStep = useCallback(() => {
    if (arrowStep - 1 < 0) return;
    if (arrowStep - 1 >= userMessages.length || arrowStep - 1 === 0) {
      setArrowStep(0);
      onStateChange({ message: "" });
      return;
    }
    setArrowStep((prev) => prev - 1);
  }, [arrowStep, userMessages.length]);

  const handleKeyDown = (event) => {
    switch (event.code) {
      case "ArrowUp":
        return handleIncreaseArrowStep();
      case "ArrowDown":
        return handleDecreaseArrowStep();
      case "Enter":
        return handleSubmit();
      default:
        break;
    }
  };

  return {
    onChange,
    handleKeyDown,
    handleSetExampleMessage,
    filterMessagesByUser,
    message,
    handleSubmit,
    sendMessage,
    messages,
    id,
    name,
    firstLoading,
    data,
    suggestionsLoading,
    suggestionsList,
    hasAnalysesInMessages,
    onStateChange,
    isExpandedList: activeChatState.isExpandedList,
  };
};
