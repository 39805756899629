export const cl = {
  root: {
    flexDirection: "column",
    p: { xs: "40px 16px", sm: "64px 112px" },
    alignItems: "center",
    gap: { xs: "24px", sm: "16px" },
    width: "100%",
    boxSizing: "border-box",
  },
  image: {
    borderRadius: "50%",
    width: "100px",
    minWidth: "100px",
    maxWidth: "100px",
    height: "100px",
    overflow: "hidden",
  },
  textContainer: {
    flexDirection: { xs: "column", sm: "row" },
    alignItems: { xs: "flex-start", sm: "center" },
    gap: "24px",
    width: "100%",
  },
  captureText: {
    fontSize: { xs: "18px", sm: "16px" },
  },
  descriptionContainer: {
    gap: "20px",
  },
  descriptionItem: {
    flexDirection: "row",
    alignItems: { xs: "flex-start", sm: "center" },
    gap: "12px",
  },
  descriptionText: {
    lineHeight: { xs: "24px", sm: "28px" },
    fontWeight: "400",
    color: "#475467",
    fontSize: { xs: "16px", sm: "18px" },
  },
};
