export const cl = {
  container: {
    p: { xs: "40px 16px", md: "34px 80px" },
    flexWrap: { xs: "wrap", md: "nowrap" },
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    justifyContent: "space-between",
    gap: { xs: "24px", md: "40px" },
    background: "#F9FAFB",
    borderTop: "1px #EAECF0 solid",
  },
  logoSection: {
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    gap: "40px",
  },
  copyrightSection: {
    flexDirection: { xs: "column", md: "row" },
    alignItems: { xs: "flex-start", md: "center" },
    gap: "5px",
  },
  link: {
    cursor: "pointer",
    color: "#6941C6",
    textDecoration: "none",
    "&:hover": {
      textDecoration: "underline",
    },
  },
  listItem: {
    fontWeight: 400,
    p: 0,
  },
};
