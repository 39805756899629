import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { HomeWrapper } from "./styles";

import { About } from "./components/About";
import { Footer } from "components/footer";
import { Header } from "./components/Header";
import { Founder } from "./components/Founder";
import LoginPopUp from "../../components/login";
import RegisterPopUp from "../../components/register";
import { WelcomeScreen } from "./components/WelcomeScreen";
import { AboutAnalysesScreen } from "./components/AboutAnalysesScreen";
import { SuccessCreatePopup } from "components/register/components/SuccessCreatePopup";

import { registerGoogleUser } from "../../actions/user";

export default function Home() {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openLogin, setOpenLogin] = useState(false);
  const [openRegister, setOpenRegister] = useState(false);
  const [openSuccessSignup, setOpenSuccessSignup] = useState(false);

  const googleResponse = ({ credential }) => {
    dispatch(registerGoogleUser(credential, () => navigate("/genie-gpt")));
  };

  const googleErrorResponse = (error) => {
    console.log(error);
  };

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    if (!queryParams) return;
    const login = queryParams.get("login");
    const signUp = queryParams.get("signUp");

    if (login && !openLogin) return setOpenLogin(true);
    if (signUp && !openRegister) return setOpenRegister(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.search]);

  return (
    <HomeWrapper container>
      <Header {...{ setOpenLogin, setOpenRegister }} />
      <WelcomeScreen {...{ setOpenRegister }} />
      <About />
      <AboutAnalysesScreen />
      <Founder />
      <Footer />
      <SuccessCreatePopup open={openSuccessSignup} setOpen={setOpenSuccessSignup} />

      <RegisterPopUp
        googleResponse={googleResponse}
        googleErrorResponse={googleErrorResponse}
        open={openRegister}
        setOpenLogin={setOpenLogin}
        handleClose={() => setOpenRegister(!openRegister)}
        successCallback={() => setOpenSuccessSignup(true)}
      />
      <LoginPopUp
        open={openLogin}
        googleResponse={googleResponse}
        googleErrorResponse={googleErrorResponse}
        setOpenRegister={setOpenRegister}
        handleClose={() => setOpenLogin(!openLogin)}
      />
    </HomeWrapper>
  );
}
