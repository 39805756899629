import { useMemo, useState } from "react";

import { AnalyseTable } from "../AnalyseTable";
import { CorelationAccordion } from "../Accordions/CorelationAccordion";

import { AccordionWrapper } from "../AnalyseItem/styled";

import { Tab, Tabs } from "@mui/material";
import { GraphBlock } from "../AnalyseItem/components/GraphBlock";
import { FrequencyAccordion } from "../Accordions/FrequencyAccordion";
import { ANALYSES_TYPES } from "constants/common.constants";

export const AnalyseTabs = ({ tabs, currentAnalyse, selectedShowTab }) => {
  const isFrequencyTables = currentAnalyse?.analysis_type === ANALYSES_TYPES.FREQUENCY_TABLES;

  const [tabData, setTabData] = useState(!!tabs.tabs.length ? tabs.tabs[0] : "");

  const handleChange = (_, newValue) => setTabData(newValue);

  const handleGetComponent = (data) => {
    switch (currentAnalyse.analysis_type) {
      case ANALYSES_TYPES.DEPENDENT:
      case ANALYSES_TYPES.COMPARE_GROUPS:
      case ANALYSES_TYPES.MULTIVARIATE_SINGLE:
        return <AnalyseTable data={data} />;
      case ANALYSES_TYPES.CORRELATIONS:
        return <CorelationAccordion data={data} />;
      default:
        break;
    }
  };

  const frequencyTablesProps = useMemo(() => {
    return {
      data: tabs.content?.variables,
      selectedShowTab,
      currentAnalyse,
      tabData,
    };
  }, [currentAnalyse, selectedShowTab, tabData, tabs.content]);

  const tableContent = {
    ...(isFrequencyTables
      ? {
          Frequency: <FrequencyAccordion {...frequencyTablesProps} />,
          Summary: <FrequencyAccordion {...frequencyTablesProps} />,
        }
      : {
          Table: (
            <AccordionWrapper>
              {handleGetComponent(tabData ? tabs.content[tabData] : tabs.content)}
            </AccordionWrapper>
          ),
        }),
    Graph: <GraphBlock {...{ currentAnalyse, independent: false, graphProps: { currentTab: tabData } }} />,
    Graphs: <GraphBlock {...{ currentAnalyse, independent: false, graphProps: { currentTab: tabData } }} />,
  };

  return (
    <>
      {tabs?.tabs?.length > 1 && (
        <Tabs
          scrollButtons={false}
          variant="scrollable"
          value={tabData}
          onChange={handleChange}
          sx={{
            "& button": {
              fontSize: "16px",
              color: "#1E1D23 !important",
            },
          }}
        >
          {tabs.tabs?.map((item, index) => (
            <Tab value={item} label={item} key={index} />
          ))}
        </Tabs>
      )}

      {!!tabs.content && !!tableContent[selectedShowTab] && tableContent[selectedShowTab]}
    </>
  );
};
