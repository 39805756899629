import { SvgIcon } from "@mui/material";

export const SmallNotificationIcon = ({ fill = "#ffff" }) => {
  return (
    <SvgIcon
      xmlns="http://www.w3.org/2000/svg"
      style={{
        width: "16px",
        height: "16px",
        fill,
      }}
      viewBox="0 0 16 16"
    >
      <path d="M8 14A6 6 0 1 1 8 2a6 6 0 0 1 0 12m-.6-6.6V11h1.2V7.4zm0-2.4v1.2h1.2V5z" fill="#375DFB" />
    </SvgIcon>
  );
};
