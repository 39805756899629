import { SubTitleText, TitleText } from "../../styles";
import { Box, Button, Stack } from "@mui/material";
import { HERO_VIDEO_URL } from "constants/common.constants";
import { gaClickCallback } from "helpers/common";
import { cl } from "./WelcomeScreen.styles";

export const WelcomeScreen = ({ setOpenRegister }) => {
  return (
    <Stack sx={cl.root}>
      <Stack sx={cl.textContainer}>
        <TitleText>
          Data to Insights <br /> Faster than Ever
        </TitleText>
        <SubTitleText>Upload your data, type your question Let Genie work its magic for you!</SubTitleText>

        <Button
          variant="contained"
          color="primary"
          sx={cl.button}
          onClick={() =>
            gaClickCallback({
              label: "Get Started For Free",
              callback: () => setOpenRegister(true),
            })
          }
        >
          Get Started For Free
        </Button>
      </Stack>
      <Stack sx={cl.videoContainer}>
        <Box
          component="video"
          autoPlay
          muted
          src={HERO_VIDEO_URL}
          alt="hero"
          loop
          onEnded={(e) => {
            e.currentTarget.play();
          }}
        />
      </Stack>
    </Stack>
  );
};
