import React, { createContext, useEffect } from "react";
import { useRoutes, useNavigate } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { routes } from "./routes";
import { Backdrop, Box, CircularProgress } from "@mui/material";
import { ErrorPopup } from "./components/errorPopup";
import axios from "axios";
import ReactGA from "react-ga4";

import { checkSession, setErrors, setShowErrors, setLoading } from "./actions/user";

import "react-toastify/dist/ReactToastify.css";
import { usePopups } from "hooks/usePopups";
import TermsOfUsePopup from "components/TermsOfUsePopup";

const API_URL = process.env.REACT_APP_API_URL;
const REACT_APP_GA4 = process.env.REACT_APP_GA4;

export const PopupContext = createContext();

function App() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const loading = useSelector((state) => state.userReducer.loading);
  const { data } = useSelector((state) => state.userReducer);

  const allowedPaths = ["/success-verification", "/error-verification"];

  useEffect(() => {
    const sessionToken = localStorage.getItem("session_token");

    if (!sessionToken && !allowedPaths.includes(window.location.pathname)) return navigate("/");

    axios.interceptors.response.use(
      function (response) {
        return response;
      },
      function (error) {
        if (error.code === "ERR_NETWORK") {
          dispatch(setLoading(false));
          dispatch(setErrors([{ msg: "Something went wrong" }]));
          dispatch(setShowErrors(true));
          return;
        }
        console.log(error);
        if (401 === error.response.status) {
          navigate("/");
        } else {
          dispatch(setLoading(false));
          if (error.config.url !== `${API_URL}/analyses/v2/statGPT/`) {
            dispatch(setErrors(error.response.data.detail));
            dispatch(setShowErrors(true));
          }
          return Promise.reject(error);
        }
      }
    );
    if (!allowedPaths.includes(window.location.pathname)) {
      dispatch(checkSession(() => navigate("/genie-gpt")));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, navigate]);

  useEffect(() => {
    ReactGA.initialize(REACT_APP_GA4);
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);

  useEffect(() => {
    if (!data?.id) return ReactGA.set({ user_id: undefined });

    return ReactGA.set({
      user_id: data?.id,
      user_name: `${data?.first_name} ${data?.last_name}`,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data?.id]);

  const content = useRoutes(routes);

  const popupsState = usePopups();

  return (
    <div className="App">
      <PopupContext.Provider value={popupsState}>
        {content}
        <ToastContainer position="top-right" autoClose={5000} theme={"colored"} />
        <ErrorPopup />
        <TermsOfUsePopup />
        <Backdrop sx={{ color: "#fff", zIndex: 10001 }} open={loading}>
          <Box display={"flex"} flexDirection="column" alignItems={"center"}>
            <CircularProgress color="inherit" />
          </Box>
        </Backdrop>
      </PopupContext.Provider>
    </div>
  );
}

export default App;
