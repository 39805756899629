import { ABOUT_SCREEN_BLOCK_DATA } from "constants/common.constants";

import { cl } from "./styles";
import { SubTitleText, TitleText } from "../../styles";

import { Box, Stack } from "@mui/material";
import { AnalysesItem } from "./components/AnalysesItem";
import { AnalysesSlider } from "./components/AnalysesSlider";
import { useMediaDevice } from "hooks/useMediaDevice";

export const AboutAnalysesScreen = () => {
  const { isMobile } = useMediaDevice();

  return (
    <Stack sx={cl.root} id="analysis">
      {ABOUT_SCREEN_BLOCK_DATA.map(({ title, img, description }, index) => (
        <Stack
          key={index}
          gap={{ xs: "24px", sm: "70px" }}
          alignItems="center"
          justifyContent="space-between"
          sx={{ flexDirection: { xs: "column", sm: index % 2 === 0 ? "row" : "row-reverse" } }}
        >
          <Box
            width={img.length > 1 ? { xs: "100%", sm: "calc(55% + 48px)" } : { xs: "100%", sm: "55%" }}
            sx={cl.imageContainer}
          >
            {img.length > 1 ? (
              <AnalysesSlider images={img} isMobile={isMobile} />
            ) : (
              <AnalysesItem url={img[0]} alt={title} isMobile={isMobile} />
            )}
          </Box>
          <Stack sx={cl.textContainer}>
            <TitleText sx={cl.titleText}>{title}</TitleText>
            <SubTitleText sx={cl.descriptionText}>{description}</SubTitleText>
          </Stack>
        </Stack>
      ))}
    </Stack>
  );
};
