import { Logo } from "components/logo";
import { ListContainer, StyledListItem } from "pages/home/components/Header/styles";
import { Grid, Stack, Typography } from "@mui/material";
import { POPUPS } from "hooks/usePopups";
import { PopupContext } from "App";
import { useContext } from "react";
import { cl } from "./footer.styles";

export const Footer = () => {
  const { setOpenPopup } = useContext(PopupContext);

  const LINKS = [{ title: "Terms of use", onClick: () => setOpenPopup(POPUPS.TERMS_OF_USE) }];

  return (
    <Grid container sx={cl.container}>
      <Stack sx={cl.logoSection}>
        <Logo />
        <Stack sx={cl.copyrightSection}>
          <Typography variant="body1" color="#70798C">
            Copyright© 2024. Genie. All Right Reserved. Contact us{" "}
          </Typography>
          <Typography component="a" sx={cl.link} href="mailto:drsharondaniel@geniestat.com">
            drsharondaniel@geniestat.com
          </Typography>
        </Stack>
      </Stack>

      <ListContainer>
        {LINKS.map(({ title, onClick }, index) => (
          <StyledListItem sx={cl.listItem} onClick={onClick} key={index}>
            {title}
          </StyledListItem>
        ))}
      </ListContainer>
    </Grid>
  );
};
