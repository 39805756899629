import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { useDispatch } from "react-redux";

import { AnalyseItem } from "../AnalyseItem";

import { SimpleMessage } from "../SimpleMessage";
import { AnalysePopup } from "../AnalysePopup";

import { setLoading } from "actions/user";
import { getAnalyse } from "services/analyses";

import { MessagesWrapper } from "./styles";

import Box from "@mui/material/Box";

export const MessageList = ({ messages, sendMessage, userData, id, firstLoading }) => {
  const lastElement = useRef(null);
  const dispatch = useDispatch();

  const [showFullScreen, setShowFullScreen] = useState(false);
  const [popUpElement, setPopUpElement] = useState(null);
  const [loading, setIsLoading] = useState(true);
  const [analysesData, setAnalysesData] = useState({});

  //const handleOpenFullScreen = (element) => {
  //  setShowFullScreen(true);
  //  setPopUpElement(element);
  //};

  const handleClosePopup = () => {
    setShowFullScreen(false);
    setPopUpElement(null);
  };

  const handleLastAnalyse = useCallback(() => {
    setIsLoading(true);
    const lastAnalyse = messages.filter(({ identity }) => identity === "system");

    const lastAnalyseId = !!lastAnalyse.length && lastAnalyse[lastAnalyse.length - 1].analysis_id;

    if (lastAnalyseId) {
      getAnalyse(lastAnalyseId).then(({ data }) =>
        setAnalysesData((prev) => ({
          ...prev,
          [lastAnalyseId]: data,
        }))
      );
    }
    setIsLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [messages]);

  const handleAllAnalyses = useCallback(
    async (data) => {
      setAnalysesData({});
      await Promise.all(
        data.map((item) =>
          getAnalyse(item).then(({ data }) =>
            setAnalysesData((prev) => ({
              ...prev,
              [item]: data,
            }))
          )
        )
      );
      dispatch(setLoading(false));
      setIsLoading(false);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const handleFirstFetch = useCallback(() => {
    const getAnalisesIdies = messages.filter((item) => item.analysis_id).map((item) => item.analysis_id);

    const uniqueSet = Array.from(new Set(getAnalisesIdies));
    setIsLoading(true);
    dispatch(setLoading(true));
    handleAllAnalyses(uniqueSet);
  }, [dispatch, handleAllAnalyses, messages]);

  useEffect(() => {
    if (!!messages.length) {
      if (firstLoading) {
        handleFirstFetch();
      } else {
        handleLastAnalyse();
      }
    }
  }, [id, handleAllAnalyses, messages, dispatch, firstLoading, handleLastAnalyse, handleFirstFetch]);

  useEffect(() => {
    if (lastElement.current && !loading) {
      setTimeout(() => {
        lastElement?.current?.scrollIntoView({ behavior: "smooth" });
      }, 150);
    }
  }, [id, loading, messages, analysesData]);

  return (
    <MessagesWrapper>
      {!loading &&
        !!messages.length &&
        messages?.map(({ text, id, identity, analysis_id, error_text, show_analysis_table }) => {
          const showAnalyseItem = identity === "system" && analysis_id && show_analysis_table && !error_text;
          return (
            <Fragment key={id}>
              {showAnalyseItem ? (
                <AnalyseItem
                  //text={text}
                  //callback={handleOpenFullScreen}
                  currentAnalyse={analysesData[analysis_id]}
                />
              ) : (
                <SimpleMessage text={text} identity={identity} userData={userData} error_text={error_text} />
              )}
            </Fragment>
          );
        })}
      <Box ref={lastElement} />
      <AnalysePopup open={showFullScreen} content={popUpElement} onClose={handleClosePopup} />
    </MessagesWrapper>
  );
};
