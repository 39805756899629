import { Box } from "@mui/material";
import React from "react";

export const AnalysesItem = ({ url, alt, isMobile = false }) => {
  return (
    <>
      {!isMobile && (
        <Box
          width="100%"
          sx={{
            border: "1px solid #a883ff4a",
            aspectRatio: "16 / 9",
            borderRadius: "14px",
            position: "absolute",
            top: "-22px",
            right: "-22px",
            opacity: 0.8,
            backgroundColor: "#ebebf590",
            zIndex: -1,
          }}
        />
      )}
      <Box component="img" src={url} alt={alt} />
    </>
  );
};
