import { useState } from "react";

import { MultivariateAccordion } from "../Accordions/MultivariateAccordion";

import { Tab, Tabs } from "@mui/material";
import { GraphBlock } from "../AnalyseItem/components/GraphBlock";

export const MultivariateAnalyse = ({ selectedShowTab, tabs, content, currentAnalyse }) => {
  const [value, setValue] = useState(0);

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const tableContent = {
    Table: <MultivariateAccordion data={content.multivariateTabs[value]} />,
    Graph: <GraphBlock {...{ currentAnalyse, independent: value === 0 ? true : false }} />,
  };

  return (
    <>
      <Tabs
        scrollButtons={false}
        variant="scrollable"
        value={value}
        onChange={handleChange}
        sx={{
          "& button": {
            fontSize: "16px",
            fontFamily: "Axiforma",
            color: "#1E1D23 !important",
          },
        }}
      >
        {tabs?.map((item, index) => (
          <Tab label={item} key={index} />
        ))}
      </Tabs>

      {!!tableContent[selectedShowTab] && tableContent[selectedShowTab]}
    </>
  );
};
