import { useDispatch } from "react-redux";

import { NavbarLink } from "./components/navbarLink";
import { AnalysePopup } from "../chatContent/components/AnalysePopup";
import { CustomMenuItem, DeleteMenuItem } from "components/userInfoBar/styles";

import { openCreateDialog } from "actions/datasets";
import { addChat, resetToDefault, setActiveChat } from "actions/chats";

import { NavbarContent, NavbarWrapper } from "./styles";

import List from "@mui/material/List";
import { ShortLogo } from "components/shortLogo";
import { Stack, Typography } from "@mui/material";
import { EmptyDataSet } from "../chatContent/components/EmptyDataSet";
import { AddDatasetBtn } from "../chatContent/components/EmptyUserScreen/styles";
import { PlusIcon } from "Icons/PlusIcon";
import { useChat } from "./useChat";
import { SimpleMenu } from "components/simpleMenu";
import { SimplePopup } from "components/simplePopup";
import { RenameDataset } from "components/renameDataset";
import { gaClickCallback } from "helpers/common";

export const ChatNavbar = () => {
  const dispatch = useDispatch();
  const {
    MENU_ITEMS,
    anchorEl,
    open,
    handleOpenMenu,
    handleClose,
    handleActiveLink,
    handleUpdateChat,
    handleDeleteChat,
    submitDatasetConfirm,
    submitNewChatConfirm,
    newChatConfirm,
    datasetConfirm,
    confirmDeleting,
    activeDataset,
    popUpElement,
    showFullScreen,
    handleCloseShowDataset,
    datasets,
    setConfirmDeleting,
    chats,
    setDatasetConfirm,
    setNewChatConfirm,
    categorizedChats,
    onCloseRenameDataset,
    openDatasetRename,
  } = useChat();

  return (
    <>
      <NavbarWrapper>
        <ShortLogo onClick={() => dispatch(resetToDefault())} />

        <NavbarContent>
          {!chats?.list?.length && (
            <Typography variant="subtitle2" fontSize="12px" color="#868C98">
              No Chat Yet
            </Typography>
          )}
          <Stack gap="16px" height="47%" direction="column">
            {!!categorizedChats?.todays?.length && (
              <Stack gap="12px" sx={{ maxHeight: !categorizedChats?.prevDays?.length ? "100%" : "50%" }}>
                <Typography
                  variant="subtitle2"
                  fontSize="12px"
                  color="#868C98"
                  sx={{ textTransform: "uppercase" }}
                >
                  Today
                </Typography>
                <List sx={{ height: "calc(100% - 20px)", overflowY: "auto" }}>
                  {categorizedChats?.todays?.map(({ name, id, ...rest }) => (
                    <NavbarLink
                      id={id}
                      key={id}
                      title={name}
                      type="chatLink"
                      active={handleActiveLink(id)}
                      handleUpdateChat={handleUpdateChat}
                      handleDeleteChat={setConfirmDeleting}
                      onClick={() => dispatch(setActiveChat({ name, id, ...rest }, true))}
                    />
                  ))}
                </List>
              </Stack>
            )}

            {!!categorizedChats?.prevDays?.length && (
              <Stack gap="12px" sx={{ maxHeight: !categorizedChats?.todays?.length ? "100%" : "50%" }}>
                <Typography
                  variant="subtitle2"
                  fontSize="12px"
                  color="#868C98"
                  sx={{ textTransform: "uppercase" }}
                >
                  Previous 7 days
                </Typography>
                <List sx={{ height: "calc(100% - 20px)", overflowY: "auto" }}>
                  {categorizedChats?.prevDays?.map(({ name, id, ...rest }) => (
                    <NavbarLink
                      id={id}
                      key={id}
                      title={name}
                      type="chatLink"
                      active={handleActiveLink(id)}
                      handleUpdateChat={handleUpdateChat}
                      handleDeleteChat={setConfirmDeleting}
                      onClick={() => dispatch(setActiveChat({ name, id, ...rest }, true))}
                    />
                  ))}
                </List>
              </Stack>
            )}
          </Stack>

          {!!datasets?.length && (
            <Stack gap="12px" maxHeight="47%">
              <Typography
                variant="subtitle2"
                fontSize="12px"
                color="#868C98"
                sx={{ textTransform: "uppercase" }}
              >
                DataSet Library
              </Typography>
              <List sx={{ height: "calc(100% - 64px)", overflowY: "auto" }}>
                {datasets?.map((dataset) => (
                  <NavbarLink
                    key={dataset.id}
                    id={dataset.id}
                    title={dataset.name}
                    type="dataset"
                    active={chats.activeDataset?.id === dataset?.id}
                    onClick={() => dispatch(addChat({ dataset }))}
                    itemCallback={(event) => handleOpenMenu(event, dataset.id, dataset.name)}
                    handleUpdateChat={handleUpdateChat}
                    handleDeleteChat={setConfirmDeleting}
                  />
                ))}
              </List>
              <AddDatasetBtn
                startIcon={<PlusIcon />}
                variant="contained"
                sx={{ mb: 0, fontSize: "14px", p: "10px" }}
                onClick={() => {
                  gaClickCallback({
                    label: "Add New Dataset on chat navbar",
                    callback: () => dispatch(openCreateDialog(true)),
                  });
                }}
              >
                Add New Dataset
              </AddDatasetBtn>
            </Stack>
          )}

          {!datasets?.length && (
            <EmptyDataSet
              onClickCallback={() =>
                gaClickCallback({
                  label: "Add New Dataset on Upload Data Set text (chat navbar)",
                  callback: () => dispatch(openCreateDialog(true)),
                })
              }
            />
          )}
        </NavbarContent>

        <SimpleMenu handleClose={handleClose} open={open} anchorEl={anchorEl}>
          {MENU_ITEMS.map(({ title, onClick, type, icon }, index) =>
            type === "delete" ? (
              <DeleteMenuItem key={index} onClick={onClick}>
                {icon} {title}
              </DeleteMenuItem>
            ) : (
              <CustomMenuItem key={index} onClick={onClick}>
                {icon} {title}
              </CustomMenuItem>
            )
          )}
        </SimpleMenu>
      </NavbarWrapper>

      <AnalysePopup
        maxHeight="90vh"
        open={showFullScreen}
        content={popUpElement}
        onClose={handleCloseShowDataset}
      />

      <RenameDataset
        handleClose={onCloseRenameDataset}
        open={openDatasetRename}
        activeDataset={activeDataset}
      />

      {confirmDeleting && (
        <SimplePopup
          submitBtnText="Delete"
          cancelBtnText="Cancel"
          title="Delete chat?"
          open={confirmDeleting}
          onClose={setConfirmDeleting}
          handleSubmit={handleDeleteChat}
          content={`This will delete ${chats.activeChat.name}.`}
        />
      )}

      {datasetConfirm && (
        <SimplePopup
          submitBtnText="Yes"
          cancelBtnText="No"
          title="Dataset Confirm"
          open={datasetConfirm}
          onClose={setDatasetConfirm}
          handleSubmit={submitDatasetConfirm}
          content="Do you want to load the dataset on the current chat?"
        />
      )}

      {newChatConfirm && (
        <SimplePopup
          submitBtnText="Yes"
          cancelBtnText="No"
          title="Dataset Confirm"
          open={newChatConfirm}
          onClose={setNewChatConfirm}
          handleSubmit={submitNewChatConfirm}
          content={`Do you want to open a new chat and load the dataset “${activeDataset.name}”?`}
        />
      )}
    </>
  );
};
