import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        sizeLarge: {
          fontSize: 15,
        },
        sizeMedium: {
          fontSize: 14,
        },
        sizeSmall: {
          fontSize: 13,
        },
        root: {
          minWidth: "100px",
        },
        contained: {
          color: "white",
          fontSize: "16px",
          fontFamily: "Inter",
          fontWeight: 600,
          lineHeight: "24px",
          padding: "9px 20px",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          borderRadius: "8px",
        },
        outlined: {
          padding: "9px 20px",
          boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)",
          borderRadius: "8px",
          border: "1px #7F56D9 solid",
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        "*": {
          boxSizing: "border-box",
        },
        html: {
          MozOsxFontSmoothing: "grayscale",
          WebkitFontSmoothing: "antialiased",
          height: "100%",
          width: "100%",
        },
        body: {
          height: "100%",
        },
        "#root": {
          height: "100%",
        },
      },
    },
    MuiTableBody: {
      styleOverrides: {
        root: {
          ".MuiTableRow-root:last-of-type": {
            ".MuiTableCell-root": {
              border: "none",
            },
          },
        },
      },
    },

    MuiAppBar: {
      styleOverrides: {
        root: {
          backgroundColor: "#ffff",
          width: "100%",
          boxSizing: "border-box",
          flexGrow: 1,
          display: "flex",
          height: "72px",
          alignItems: "center",
          boxShadow: "rgba(149, 157, 165, 0.15) 0px 6px 18px",
          svg: {
            cursor: "pointer",
            transition: "all 0.2s",

            "&:hover": {
              color: "var(--main)",
            },
          },
        },
      },
    },

    MuiDialog: {
      styleOverrides: {
        paper: {
          borderRadius: "20px",
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontSize: "24px",
          fontWeight: 700,
          padding: "24px 24px 28px",
          color: "#101828",
          lineHeight: "24px",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "28px 24px 24px",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0 24px",
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: "8px",

          ".MuiInputBase-input": {
            padding: "10.5px 14px",
            lineHeight: "24px",
            fontSize: "16px",
            color: "#506176",
          },
        },
      },
    },

    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: "#8593AD",
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          minWidth: "20px !important",
        },
      },
    },
  },
  palette: {
    background: {
      default: "#F8FAFF",
      paper: "#ffffff",
    },
    primary: {
      contrastText: "#ffffff",
      main: "#7F56D9",
    },
    secondary: {
      contrastText: "#ffffff",
      main: "#1CB4AE",
    },
    info: {
      contrastText: "#163B65",
      main: "#ECEBEF",
    },
    text: {
      primary: "#163B65",
      secondary: "#525866",
    },
  },
  shadows: [
    "none",
    "0px 1px 2px rgba(9, 30, 66, 0.2)",
    "0px 1px 3px rgba(9, 30, 66, 0.12)",
    "0px 2px 4px rgba(9, 30, 66, 0.08)",
    "0px 3px 5px rgba(9, 30, 66, 0.08)",
    "0px 3px 5px -1px rgba(9, 30, 66, 0.08)",
    "0px 5px 7px rgba(9, 30, 66, 0.08)",
    "0px 6px 8px rgba(9, 30, 66, 0.08)",
    "0px 8px 12px rgba(9, 30, 66, 0.08)",
    "0px 9px 14px rgba(9, 30, 66, 0.08)",
    "0px 10px 16px rgba(9, 30, 66, 0.08)",
    "0px 11px 18px rgba(9, 30, 66, 0.08)",
    "0px 12px 20px rgba(9, 30, 66, 0.08)",
    "0px 13px 22px rgba(9, 30, 66, 0.08)",
    "0px 14px 24px rgba(9, 30, 66, 0.08)",
    "0px 15px 26px rgba(9, 30, 66, 0.08)",
    "0px 18px 28px rgba(9, 30, 66, 0.08)",
    "0px 20px 30px rgba(9, 30, 66, 0.08)",
    "0px 22px 32px rgba(9, 30, 66, 0.08)",
    "0px 24px 34px rgba(9, 30, 66, 0.08)",
    "0px 26px 36px rgba(9, 30, 66, 0.08)",
    "0px 28px 38px rgba(9, 30, 66, 0.08)",
    "0px 30px 40px rgba(9, 30, 66, 0.08)",
    "0px 32px 42px rgba(9, 30, 66, 0.08)",
    "0px 36px 46px rgba(9, 30, 66, 0.12)",
  ],
  shape: {
    borderRadius: 6,
  },
  typography: {
    fontFamily: "Inter, sans-serif",
    h1: {
      fontSize: 48,
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#101828",
    },
    h2: {
      fontSize: 36,
      fontWeight: 600,
      lineHeight: "44px",
      color: "#101828",

      "@media (max-width:600px)": {
        fontSize: "24px",
        lineHeight: "32px",
      },
    },
    h3: {
      fontSize: 32,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    h4: {
      fontSize: 24,
      fontWeight: 600,
      lineHeight: 1.5,
      color: "#101828",
    },
    h5: {
      fontSize: 18,
      fontWeight: 400,
      lineHeight: 1.5,
      color: "#475467",
    },
    h6: {
      fontSize: 16,
      fontWeight: 600,
      lineHeight: 1.5,
    },
    body1: {
      color: "#475467",
      fontSize: 16,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: "24px",
    },
    body2: {},
    subtitle1: {
      fontSize: 16,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: 1.75,
    },
    subtitle2: {
      fontSize: 14,
      fontWeight: 600,
      letterSpacing: 0,
      lineHeight: "20px",
    },
    caption: {
      color: "#6941C6",
      fontWeight: 600,
      fontSize: 16,
      lineHeight: "24px",
    },
    overline: {
      fontSize: 12,
      fontWeight: 600,
      letterSpacing: 1,
      lineHeight: 1.66,
    },
    button: {
      fontWeight: 500,
      textTransform: "none",
    },
    error: {
      color: "#d32f2f",
      fontSize: 12,
      fontWeight: 400,
      fontFamily: "Prompt, sans-serif",
      marginBottom: "3px",
    },
  },
});

export default theme;
