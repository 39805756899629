import { useEffect, useMemo, useState } from "react";

import { AnalyseTabs } from "../AnalyseTabs";
import { MultivariateAnalyse } from "../MultivariateAnalyse";

import { AnalyseMessageItem } from "../MessageList/styles";
import { HeaderBlock } from "./components/HeaderBlock";
import { FooterBlock } from "./components/FooterBlock";
import { gaClickCallback } from "helpers/common";
import { ANALYSES_TYPES } from "constants/common.constants";

export const ITEM_TABS = ["Table", "Graph"];
export const FREQUENCY_ITEM_TABS = ["Frequency", "Summary", "Graphs"];

export const AnalyseItem = ({ currentAnalyse }) => {
  const isFrequencyTables = currentAnalyse?.analysis_type === ANALYSES_TYPES.FREQUENCY_TABLES;

  const [tabs, setTabs] = useState({});
  const [selectedShowTab, setSelectedShowTab] = useState(
    isFrequencyTables ? FREQUENCY_ITEM_TABS[0] : ITEM_TABS[0]
  );

  const data = useMemo(() => currentAnalyse?.summary?.data, [currentAnalyse?.summary?.data]);

  const handleSetCurrentTab = () => {
    switch (currentAnalyse.analysis_type) {
      case ANALYSES_TYPES.DEPENDENT:
      case ANALYSES_TYPES.COMPARE_GROUPS:
      case ANALYSES_TYPES.MULTIVARIATE_SINGLE:
        return compareGroupsTabs(data);
      case ANALYSES_TYPES.CORRELATIONS:
        return correlationsTabs(data);
      case ANALYSES_TYPES.MIXED_MULTIVARIATE:
      case ANALYSES_TYPES.MULTIVARIATE:
        return multivariateTabs(data);
      case ANALYSES_TYPES.FREQUENCY_TABLES:
        return frequencyTablesTabs(data);
      default:
        break;
    }
  };

  useEffect(() => {
    if (!currentAnalyse) return;

    setSelectedShowTab(isFrequencyTables ? FREQUENCY_ITEM_TABS[0] : ITEM_TABS[0]);
    handleSetCurrentTab(currentAnalyse.analysis_type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentAnalyse]);

  const correlationsTabs = (data) => {
    if (data?.all) {
      let correlationData = {
        matrix: data.all.matrix,
      };
      let keys = Object.keys(data.all.tables);
      keys.forEach((key) => {
        correlationData[key] = data.all.tables[key];
      });

      setTabs({
        content: correlationData,
        tabs: [],
      });
    } else {
      console.log("with split");
      // let keys = Object.keys(data);
      // keys.forEach((key) => {
      //   let correlationData = {
      //     matrix: data[key].matrix,
      //   };
      //   let keys2 = data[key]?.tables ? Object.keys(data[key]?.tables) : null;
      //   keys2?.forEach((key2) => {
      //     correlationData[key2] = data[key].tables[key2];
      //   });
      // });
    }
  };

  const frequencyTablesTabs = (data) => {
    if (!data?.all) return;

    let frequencyTablesData = {
      variables: data.all.tables,
      graphs: currentAnalyse?.summary?.images?.all?.main_independents,
    };

    setTabs({
      content: frequencyTablesData,
      tabs: [],
    });
  };

  const compareGroupsTabs = (data) => {
    if (data?.all) {
      let keys = Object.keys(data?.all?.tables);

      setTabs({
        content: data.all.tables,
        tabs: keys,
      });
    } else {
      let keys = Object.keys(data);
      keys.forEach((key) => {
        let keys2 = Object.keys(data[key].tables);
        setTabs({
          content: data[key].tables,
          tabs: keys2,
        });
      });
    }
  };

  const multivariateTabs = (data) => {
    let multivariateTabs = [];
    let images = [];

    if (data?.all) {
      let keys = Object.keys(data.all.tables.all_models);
      multivariateTabs.push(data.all.tables.main_independents);
      images.push(currentAnalyse?.summary?.images.all.main_independents);

      keys.forEach((key) => {
        multivariateTabs.push(data.all.tables.all_models[key]);
        images.push(currentAnalyse?.summary?.images.all.all_models[key]);
      });
    } else {
      let keys = Object.keys(data);
      keys.forEach((key, index) => {
        let keys2 = Object.keys(data[key].tables.all_models);
        multivariateTabs.push(data[key].tables.main_independents);
        images.push(currentAnalyse?.summary?.images[key].main_independents);

        keys2.forEach((key2) => {
          multivariateTabs.push(data[key].tables.all_models[key2]);
          images.push(currentAnalyse?.summary?.images[key].all_models[key2]);
        });
      });
    }
    return setTabs({ multivariateTabs, images });
  };

  const showFooterBlock = useMemo(
    () =>
      selectedShowTab === ITEM_TABS[0] ||
      selectedShowTab === FREQUENCY_ITEM_TABS[0] ||
      selectedShowTab === FREQUENCY_ITEM_TABS[1],
    [selectedShowTab]
  );

  const normalizedSelectedShowTab = useMemo(() => {
    return isFrequencyTables && !FREQUENCY_ITEM_TABS.includes(selectedShowTab)
      ? FREQUENCY_ITEM_TABS[0]
      : selectedShowTab;
  }, [selectedShowTab, isFrequencyTables]);

  return (
    <AnalyseMessageItem column="true">
      <HeaderBlock
        tabs={
          currentAnalyse?.analysis_type === ANALYSES_TYPES.FREQUENCY_TABLES ? FREQUENCY_ITEM_TABS : ITEM_TABS
        }
        currentAnalyse={currentAnalyse}
        value={normalizedSelectedShowTab}
        handleChange={(_, newValue) => {
          gaClickCallback({
            label: `${newValue} analyse tab`,
            callback: () => setSelectedShowTab(newValue),
          });
        }}
      />
      {!!tabs?.multivariateTabs?.length && (
        <MultivariateAnalyse
          tabs={["Main Independent Variable", "All Models"]}
          content={tabs}
          currentAnalyse={currentAnalyse}
          {...{ selectedShowTab }}
        />
      )}
      {tabs.content && (
        <AnalyseTabs tabs={tabs} currentAnalyse={currentAnalyse} selectedShowTab={selectedShowTab} />
      )}

      {showFooterBlock && <FooterBlock id={currentAnalyse?.id} />}
    </AnalyseMessageItem>
  );
};
