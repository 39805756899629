import { cl, MobileListContainer } from "../styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

import { HEADER_LINKS } from "../header.constants";
import { StyledListItem } from "../styles";
import { Button } from "@mui/material";
import { gaClickCallback } from "helpers/common";

export const MenuContent = ({ open, setOpen, setOpenLogin, setOpenRegister }) => {
  const handleUserRedirect = (route) => {
    const element = document.getElementById(route);

    if (element) {
      setOpen(false);
      element?.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return (
    <>
      <Grid sx={{ ...cl.menu, ...(open && cl.menuActive) }}>
        <Box sx={cl.menuWrapper}>
          <MobileListContainer>
            {HEADER_LINKS.map(({ title, route }, index) => (
              <StyledListItem
                sx={{ p: { xs: "8px 0px", md: 0 } }}
                onClick={() => handleUserRedirect(route)}
                key={index}
              >
                {title}
              </StyledListItem>
            ))}
          </MobileListContainer>
          <MobileListContainer sx={{ gap: "12px", p: "11px 20px" }}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                gaClickCallback({
                  label: "Login",
                  callback: () => setOpenLogin(true),
                });
              }}
            >
              Login
            </Button>
            <Button
              variant="contained"
              color="primary"
              sx={{ height: "44.5px" }}
              onClick={() => {
                gaClickCallback({
                  label: "Sign up",
                  callback: () => setOpenRegister(true),
                });
              }}
            >
              Sign up
            </Button>
          </MobileListContainer>
        </Box>
      </Grid>
    </>
  );
};
