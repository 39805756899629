import { useNavigate } from "react-router-dom";

import { Logo } from "components/logo";
import { SubTitleText, TitleText } from "pages/home/styles";

import { Button, Stack } from "@mui/material";

export default function SuccessVerification() {
  const navigate = useNavigate();

  return (
    <Stack
      direction="column"
      alignItems="center"
      justifyContent="center"
      height="100svh"
      textAlign="center"
      gap="30px"
      width="100%"
    >
      <Logo sx={{ width: "180px", height: "38px" }} />

      <Stack gap="6px">
        <TitleText sx={{ fontSize: "28px", lineHeight: "40px" }}>Account Verified!</TitleText>
        <SubTitleText sx={{ fontSize: "18px", lineHeight: "26px" }}>
          Your account has been successfully verified. <br />
          You can now access Genie Statistics.
        </SubTitleText>
      </Stack>

      <Button
        sx={{ width: "220px", height: "46px" }}
        variant="contained"
        color="primary"
        onClick={() => navigate("/?login=true")}
      >
        Sign In
      </Button>
    </Stack>
  );
}
