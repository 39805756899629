export const cl = {
  root: {
    flexDirection: { xs: "column-reverse", sm: "row" },
    p: { xs: "40px 16px", sm: "40px 80px 80px" },
    alignItems: "center",
    gap: { xs: "24px", sm: "64px" },
    justifyContent: "space-between",
    width: "100%",
    boxSizing: "border-box",
  },
  textContainer: {
    gap: "12px",
    width: { xs: "100%", sm: "60%" },
  },
  captureText: {
    fontSize: { xs: "18px", sm: "16px" },
  },
  title: {
    mb: { xs: "12px", sm: "20px" },
  },
  subtitle: {
    fontWeight: 700,
    color: { xs: "#101828", sm: "#475467" },
  },
  description: {
    gap: { xs: "20px", sm: "32px" },
  },
  descriptionItem: {
    lineHeight: { xs: "20px", sm: "28px" },
    fontWeight: { xs: "400", sm: "500" },
    color: { xs: "#101828", sm: "#475467" },
    fontSize: { xs: "14px", sm: "18px" },
  },
  imageContainer: {
    width: { xs: "100%", sm: "40%" },
  },
  image: {
    borderRadius: "8px",
  },
};
