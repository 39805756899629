import * as Yup from "yup";
import { useContext } from "react";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import { useNavigate } from "react-router-dom";
import { GoogleLogin, GoogleOAuthProvider } from "@react-oauth/google";

import { loginRequest } from "../../actions/user";

import { GOOGLE_ID } from "../register/register.constants";
import { DEFAULT_USER_DATA, getLoginFields } from "./login.constants";

import { TextInput, ErrorText, GreenSpan, StyledDialog, StyledDialogContent, cl } from "../register/styles";

import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { useRef } from "react";
import { useEffect } from "react";
import { Label } from "./styles";
import { HeaderBlock } from "./components/HeaderBlock";
import { gaClickCallback } from "helpers/common";
import { GA4_ACTION_TYPES } from "constants/common.constants";
import { PopupContext } from "App";
import { POPUPS } from "hooks/usePopups";
import { useMediaDevice } from "hooks/useMediaDevice";

export default function LoginPopUp({
  open,
  handleClose,
  googleResponse,
  setOpenRegister,
  googleErrorResponse,
}) {
  const { setOpenPopup } = useContext(PopupContext);
  const { isMobile } = useMediaDevice();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: DEFAULT_USER_DATA,
    onSubmit: (data) => {
      dispatch(loginRequest(data, () => navigate("/genie-gpt")));
      onClose();
    },
    validationSchema: Yup.object().shape({
      email: Yup.string().email("Email is not valid").required("Required field"),
      password: Yup.string()
        .required("Required field")
        .min(3, "Password must be at least 8 characters")
        .max(255, "Password cannot be longer than 255 characters"),
    }),
  });

  const dispatch = useDispatch();
  let navigate = useNavigate();

  const onClose = () => {
    formik.resetForm();
    handleClose();
  };

  const handleOpenSignUp = (event) => {
    event.stopPropagation();
    handleClose();
    setOpenRegister(true);
  };

  const fields = getLoginFields();

  const btnRef = useRef(null);

  const onTermsOpen = () => setOpenPopup(POPUPS.TERMS_OF_USE);

  useEffect(() => {
    if (btnRef.current) {
      btnRef.current.style.display = "none";
    }
  }, [btnRef]);

  return (
    <StyledDialog open={open} onClose={onClose} fullWidth sx={cl.dialogSx}>
      <HeaderBlock
        title="Log in to your account"
        subTitle="Welcome back! Please enter your details"
        onClose={onClose}
      />
      <form onSubmit={formik.handleSubmit}>
        <StyledDialogContent>
          <Grid container gap="16px" width="100%">
            {fields.map(({ name, label, type }, index) => (
              <Box sx={{ width: "100%" }} key={index}>
                <Label>{label}*</Label>
                <TextInput
                  fullWidth
                  type={type}
                  name={name}
                  placeholder={label}
                  value={formik.values[name]}
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  error={!!formik.errors[name] && !!formik.touched?.[name] && !!formik.errors[name]}
                />
                <ErrorText>
                  {!!formik.errors[name] && !!formik.touched?.[name] && formik.errors[name]}
                </ErrorText>
              </Box>
            ))}
          </Grid>

          <Grid container gap="12px" alignItems="center" flexDirection="column">
            <Button
              type="submit"
              variant="contained"
              color="primary"
              onClick={() => gaClickCallback({ label: "Login Continue button" })}
            >
              Continue
            </Button>

            <GoogleOAuthProvider clientId={GOOGLE_ID}>
              <GoogleLogin
                size="large"
                onSuccess={googleResponse}
                onError={googleErrorResponse}
                width={isMobile ? "307" : "392"}
              />
            </GoogleOAuthProvider>

            <Typography fontSize="14px" mt="24px" lineHeight="20px">
              Don't have an account?
              <GreenSpan
                onClick={(e) =>
                  gaClickCallback({
                    type: GA4_ACTION_TYPES.LINK,
                    label: "Sign up",
                    callback: () => handleOpenSignUp(e),
                  })
                }
              >
                Sign up
              </GreenSpan>
            </Typography>
            <Typography fontSize="14px" lineHeight="20px" onClick={onTermsOpen}>
              <GreenSpan onClick={onTermsOpen}>Terms of use</GreenSpan>
            </Typography>
          </Grid>
        </StyledDialogContent>
      </form>
    </StyledDialog>
  );
}
