export const cl = {
  dialogWrapper: {
    background: "white",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "flex-start",
    display: "inline-flex",
    overflow: "hidden",
    padding: "20px 16px",
    borderRadius: "8px",
    overflowY: "auto",
    maxHeight: "88svh",
    gap: "20px",
    minWidth: { xs: "83%", md: "45%" },
  },
};
