import React from "react";
import LogoImage from "../../images/logo.png";

import { Box } from "@mui/material";

export const Logo = (props) => {
  return (
    <Box
      component="img"
      src={LogoImage}
      sx={{
        width: "109px",
        height: "24.5px",
      }}
      {...props}
    />
  );
};
