import { useMediaQuery } from "@mui/material";

const MOBILE_QUERY = "(max-width: 600px)";
const TABLET_QUERY = "(min-width: 600px) and (max-width: 1024px)";

export const useMediaDevice = () => {
  const isMobileDevice = useMediaQuery(MOBILE_QUERY);
  const isTabletDevice = useMediaQuery(TABLET_QUERY);

  return {
    isMobile: !!isMobileDevice,
    isTablet: !!isTabletDevice,
  };
};
